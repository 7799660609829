<template>
	<div class="row">
		<div class="col-10">
			<q-table title="Список отчетов" :rows="table_rows" :columns="table_columns" row-key="name" flat
				separator="cell" :pagination="tablePagination" @row-click="onRowClick" v-model="table_rows">

			</q-table>
		</div>
		<div class="col-2 q-pa-md">
			<q-date v-model="period_range.from" flat minimal
				@update:model-value="(newValue) => { onPeriodChange({ from: newValue, to: period_range.to }) }" />
			<q-date v-model="period_range.to" flat minimal
				@update:model-value="(newValue) => { onPeriodChange({ from: period_range.from, to: newValue }) }" />
			<div v-if="period_range == null">
				Период не установлен
			</div>
			<div v-else>
				Период c {{ prettyDatePrint(period_range.from) }} по {{ prettyDatePrint(period_range.to) }}
			</div>
		</div>
	</div>
	<q-page-sticky position="bottom-right" :offset="[18, 18]">
		<q-fab icon="add" direction="up" color="primary">
			<q-fab-action anchor="start" @click="onClick" color="primary" label="Отчет о финансовых результатах" />
			<q-fab-action anchor="start" @click="onCreateNewRole" color="primary" label="Бухгалтерский баланс" />
			<q-fab-action anchor="start" @click="onClick" color="primary" label="Отчет об изменениях
			капитала" />
			<q-fab-action anchor="start" @click="onCreateNewRole" color="primary"
				label="Отчет о движении денежных средств" />
		</q-fab>
	</q-page-sticky>
</template>

<script>
import { ref } from "vue";
import moment from "moment";

const document_direction_options = [
	{
		label: 'Любое',
		value: 'all'
	},
	{
		label: 'Входящий',
		value: 'in'
	},
	{
		label: 'Исходящий',
		value: 'out'
	},
	{
		label: 'Внутренний',
		value: 'internal'
	}
]

const table_columns = [
	{ name: 'in_org_number', align: 'left', label: 'Номер', field: 'in_org_number', sortable: true },
	{ name: 'name', align: 'left', label: 'Название', field: 'name' },
	{ name: 'user', align: 'left', label: 'Пользователь', field: 'user', sortable: true },
	{ name: 'direction', align: 'left', label: 'Направление', field: 'direction_display', sortable: true },
	{ name: 'date', align: 'left', label: 'Дата', field: 'date', sortable: true },
]

export default {
	name: "reports_list",
	components: {
	},
	data() {
		this.emitter.on('didReceiveDocumentsList', (data) => {
			console.log(data)
			this.table_rows = data.data;
			this.isUpdatingData = false;
		})
		this.emitter.on('didReceiveDocumentsTemplates', (data) => {
			console.log('ororororr', data)
			this.templates_list_options = [{ label: 'Все', value: 'all' }].concat(data.data.map((element) => { return { label: element.name, value: element.id } }));
		})
		const period_start = moment().quarter(moment().quarter()).startOf('quarter').endOf('day');
		const period_end = moment().endOf('day');
		const period_start_unix = period_start.unix();
		const period_end_unix = period_end.unix();
		if (this.connection.isAuthenticated) {
			this.connection.getDocumentsList(
				'1',
				this.$store.state.current_org_id,
				period_start_unix,
				period_end_unix,
				'all',
				'report'
			);
		} else {
			this.emitter.on('connectionReady', () => {
				this.connection.getDocumentsList(
					'1',
					this.$store.state.current_org_id,
					period_start_unix,
					period_end_unix,
					'all',
					'report'
				);
			})
		}
		return {
			isUpdatingData: ref(true),
			table_rows: ref([]),
			period_start_unix: ref(period_start_unix),
			period_end_unix: ref(period_end_unix),
			incoming: ref('all'),
			template_id: ref({ label: 'Все', value: -1 }),
			templates_list_options: ref([]),
			document_direction: ref(document_direction_options[0]),
			document_direction_options: document_direction_options,
			period_range: ref(this.$store.state.filter_period)
		}
	},
	setup() {
		moment.locale('ru');
		const period_start_proxy = ref(moment().quarter(moment().quarter()).startOf('quarter').format("YYYY/MM/DD"));
		const period_end_proxy = ref(moment().endOf('day').format("YYYY/MM/DD"));
		const period_start = ref(moment().quarter(moment().quarter()).startOf('quarter').format("YYYY/MM/DD"));
		const period_end = ref(moment().endOf('day').format("YYYY/MM/DD"));
		return {
			create_new_bill: ref(false),
			create_new_transfer: ref(false),
			table_columns,

			tablePagination: {
				rowsPerPage: 0
			},
			period_start_proxy,
			period_end_proxy,
			period_start,
			period_end
		}
	},
	methods: {
		onRowClick(event, row) {
			if (row.document_type === 'report') {
				this.$router.push({ name: 'document_info_report', params: { id: row.id, history_id: 'last' } })
			}

		},
		onSubRowClick(event, row) {
			console.log(row);
		},
		onPeriodChange(model_value) {
			console.log(model_value);
			if (model_value == null) {
				return;
			}
			this.$store.commit('updateFilterPeriod', model_value);
			this.isUpdatingData = true;
			if (this.connection.isAuthenticated) {
				this.connection.getDocumentsList(
					'1',
					this.$store.state.current_org_id,
					moment(model_value.from).endOf('day').unix(),
					moment(model_value.to).endOf('day').unix(),
					'all',
					'report'
				);
			} else {
				this.emitter.on('connectionReady', () => {
					this.connection.getDocumentsList(
						'1',
						this.$store.state.current_org_id,
						moment(model_value.from).endOf('day').unix(),
						moment(model_value.to).endOf('day').unix(),
						'all',
						'report'
					);
				})
			}
		},
		prettyDatePrint(date) {
			return moment(date).format('DD MMMM YYYY');
		}
	},
}
</script>

<style scoped></style>