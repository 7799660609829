<template>
  <div class="">
    <q-btn @click="onPrintButtonClick" unelevated color="white" icon-right="download" text-color="primary"
      label="Печать" />
    <q-btn @click="onEditDocument" unelevated color="white" icon-right="edit" text-color="primary"
      label="Проводки и редактирование" />
    <q-btn unelevated color="white" icon-right="download" text-color="primary" label="Скачать" @click="onDownload" />
    <q-btn v-if="is_active" @click="onDeleteDocument" unelevated color="white" icon-right="delete" text-color="negative"
      label="Удалить" />
    <q-btn v-else @click="onRestoreDocument" unelevated color="white" icon-right="history" text-color="primary"
      label="Восстановить" />

    <q-toggle v-model="body.maked" disable label="Исполнено" />
  </div>

  <div class="doc landscape upd" id="print_content">
    <div class="container">
      <div style="display: block; margin-bottom: 3mm;">
        <div style="display: flex;">
          <div style="display: inline;  text-align: center;">
            <div style="width: 35mm; height: 5mm; border-bottom: 0.6mm black solid;">{{ body.postup_bank_plat || "" }}
            </div>
            <div style=" font-size: 10px;">Поступление в банк плат.</div>
          </div>
          <div style="display: inline;  margin-left: 25mm; text-align: center;">
            <div style="width: 35mm; height: 5mm; border-bottom: 0.6mm black solid;">{{ body.spis_sch_plat || "" }}
            </div>
            <div style=" font-size: 10px;">Списано со сч. плат.</div>
          </div>
          <div style="display: inline; margin-top: 5mm; margin-left: 70mm; text-align: center;">
            <div style="width: 15mm; height: 5mm; border: 0.6mm black solid;">
              0401060
            </div>
          </div>
        </div>
      </div>

      <div>
        <div style="display: flex;">
          <div style="padding-top: 2mm; display: inline; width: 92.5mm; font-weight: bold; font-size: 12pt">ПЛАТЕЖНОЕ
            ПОРУЧЕНИЕ №{{ body.in_org_number || "" }}</div>
          <div style="padding-top: 2mm;display: inline; text-align: center;">
            <div style="border-bottom: 0.6mm black solid; width: 35mm;">{{ formatDate(body.document_date || 0) }}</div>
            <div style="font-size: 10px;">Дата</div>
          </div>
          <div style="padding-top: 2mm;display: inline; margin-left: 5mm; text-align: center;">
            <div style="border-bottom: 0.6mm black solid; width: 35mm;">{{ body.plat_kind || "" }}</div>
            <div style="font-size: 10px;">Вид платежа </div>
          </div>

          <div style="display: inline; margin-left: 10mm; ">
            <div style="width: 7.5mm; height: 7.5mm; border: 0.6mm black solid;">

            </div>
          </div>
        </div>

      </div>
      <table style="width: 180mm !important;">
        <tr style="height: 15mm;">
          <td class="no-left-border" style="border-top: transparent !important; text-align: left;" colspan="4"
            rowspan="1">Сумма прописью</td>
          <td class="no-right-border" style="border-top: transparent !important; text-align: left;" colspan="32"
            rowspan="1">{{ body.summ_letter || "" }}</td>
        </tr>
        <tr style="height: 5mm;">
          <td class="no-left-border" style="height: 5mm !important; text-align: left;" colspan="10">ИНН {{ body.plat_inn
            || "" }}</td>
          <td colspan="10" style="height: 5mm !important; text-align: left;">КПП {{ body.plat_kpp || "" }}</td>
          <td colspan="3" style="height: 15mm !important;" rowspan="2">Сумма</td>
          <td class="no-right-border" style="height: 15mm !important; text-align: left;" colspan="13" rowspan="2">{{
            body.summ || "" }}</td>
        </tr>
        <tr>
          <td class="no-left-border aligned-cells" style="height: 25mm !important;" colspan="20" rowspan="2">
            <div>{{ body.plat || "" }}</div>
            <div>Плательщик</div>
          </td>
        </tr>
        <tr>
          <td colspan="3" style="height: 15mm !important;" rowspan="1">Сч. №</td>
          <td class="no-right-border" style="height: 30mm !important; text-align: left;" colspan="13" rowspan="3">
            <div style="height: 18mm; padding-top: 1mm;">
              {{ body.plat_bill_num || "" }}
            </div>
            <div style="height: 6mm; padding-top: 1mm;">
              {{ body.plat_bik || "" }}
            </div>
            <div style="height: 5mm; padding-top: 2mm;">
              {{ body.plat_bill_num_ext || "" }}
            </div>
          </td>
        </tr>
        <tr>
          <td class="no-left-border aligned-cells" style="height: 15mm !important;" colspan="20" rowspan="2">
            <div>{{ body.plat_bank || "" }}</div>
            <div>Банк плательщика </div>
          </td>
          <td colspan="3">БИК</td>
        </tr>
        <tr>
          <td colspan="3">Сч. №</td>
        </tr>
        <tr>
          <td class="no-left-border aligned-cells" style="height: 15mm !important;" colspan="20" rowspan="2">
            <div>{{ body.rec_bank || "" }}</div>
            <div>Банк получателя</div>
          </td>
          <td colspan="3">БИК</td>

          <td class="no-right-border" style="text-align: left;" colspan="13" rowspan="4">
            <div style="height: 7mm; padding-top: 1mm;">
              {{ body.rec_bik || "" }}
            </div>
            <div style="height: 7mm; padding-top: 1mm;">
              {{ body.rec_bill_num || "" }}
            </div>
            <div style="height: 5mm; padding-top: 1mm;">
              {{ body.rec_bill_num_ext || "" }}
            </div>
          </td>
        </tr>
        <tr>
          <td colspan="3">Сч. №</td>
        </tr>
        <tr>
          <td class="no-left-border" style="height: 7.5mm !important; text-align: left;" colspan="10">ИНН {{
            body.rec_inn || "" }}</td>
          <td colspan="10" style="text-align: left;">КПП {{ body.rec_kpp || "" }}</td>
          <td colspan="3" rowspan="2">Сч. №</td>
        </tr>
        <tr>
          <td class="no-left-border aligned-cells" style="height: 25mm !important;" colspan="20" rowspan="4">
            <div>{{ body.rec || "" }}</div>
            <div>Получатель</div>
          </td>
        </tr>
        <tr>
          <td colspan="3">Вид. оп</td>
          <td colspan="4" rowspan="3">
            <div style="height: 5mm; text-align: left; padding-left: 2mm; padding-top: 1mm">{{ body.vid_op || "" }}
            </div>
            <div style="height: 6mm; text-align: left; padding-left: 2mm; padding-top: 2mm">{{ body.naz_pl || "" }}
            </div>
            <div style="height: 5mm; text-align: left; padding-left: 2mm; padding-top: 2mm">{{ body.code || "" }}</div>
          </td>
          <td colspan="4">Срок плат.</td>
          <td class="no-right-border" colspan="5" rowspan="3">
            <div style="height: 5mm; text-align: left; padding-left: 2mm; padding-top: 1mm">{{ body.ts_plat || "" }}
            </div>
            <div style="height: 6mm; text-align: left; padding-left: 2mm; padding-top: 2mm">{{ body.naz_pl_ext || "" }}
            </div>
            <div style="height: 5mm; text-align: left; padding-left: 2mm; padding-top: 2mm">{{ body.rez_field || "" }}
            </div>
          </td>
        </tr>
        <tr>
          <td colspan=" 3">Наз. пл.
          </td>
          <!-- <td colspan="2"><q-input /></td> -->
          <td colspan="4">Очер. плат.</td>
          <!-- <td colspan=""><q-input /></td> -->
        </tr>
        <tr>
          <td colspan="3">Код</td>
          <!-- <td colspan="2"><q-input /></td> -->
          <td colspan="4">Рез. поле</td>
          <!-- <td colspan="1"><q-input /></td> -->
        </tr>
        <tr>
          <td class="no-left-border" colspan="9">{{ body.ext_field_1 || "" }}</td>
          <td colspan="6">{{ body.ext_field_2 || "" }}</td>
          <td colspan="2">{{ body.ext_field_3 || "" }}</td>
          <td colspan="5">{{ body.ext_field_4 || "" }}</td>
          <td colspan="7">{{ body.ext_field_5 || "" }}</td>
          <td colspan="5">{{ body.ext_field_6 || "" }}</td>
          <td colspan="2" class="no-right-border">{{ body.ext_field_7 || "" }}</td>
        </tr>
      </table>
      <div style="margin-top: 2mm; font-size: 10pt;">
        {{ body.naz_pl_full || "" }}<br/>
        {{ body.nds_displayed || '' }}
      </div>
      <div style="margin-top: 20mm; font-size: 10pt;">
        Назначение платежа 
      </div>
      <div style="width: 180mm; border-top: 0.6mm black solid; font-size: 10pt;">
        <div style="display: inline; margin-left: 80mm;">
          Подписи
        </div>
        <div style="display: inline;margin-left: 50mm;">
          Отметки банка
        </div>
      </div>

      <div style="margin-top: 10mm; height: 5mm; width: 100%; text-align: center;">
        <div style="width: 60mm; margin: 0px auto; border-bottom: 0.6mm black solid;"></div>
      </div>
      <div style="height: 10mm; text-align: left; vertical-align: middle; padding-left: 25mm; font-size: 10pt;">
        М.П.
      </div>
      <div style="height: 5mm; width: 100%; text-align: center;">
        <div style="width: 60mm; margin: 0px auto; border-bottom: 0.6mm black solid;"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import moment from "moment";

var style = "<style>" +
  "@page {" +
  "size: A4;" +
  "margin: 0;" +
  "}" +
  ".container {" +
  "margin: 0;" +
  "padding: 0;" +
  "font-family: 'Arial', sans-serif;" +
  "font-size: 12px;" +
  "background: white;" +
  "color: black;" +
  "width: 210mm;" +
  "height: 297mm;" +
  "padding: 10mm 10mm 10mm 20mm;" +
  "box-sizing: border-box;" +
  "display: block;" +
  "margin: auto;" +
  "position: relative;" +
  "}" +
  "table {" +
  "width: 180mm;" +
  "height: 110mm;" +
  "border-collapse: collapse;" +
  "table-layout: fixed;" +
  "margin-top: 10px;" +
  "font-size: 8pt;" +
  "}" +
  "th, td {" +
  "border: 0.6mm solid black;" +
  "height: 5mm;" +
  "text-align: center;" +
  "vertical-align: top;" +
  "}" +
  "tr {" +
  "height: 5mm;" +
  "}" +
  ".aligned-cells {" +
  "text-align: left;" +
  "vertical-align: bottom;" +
  "}" +
  ".no-right-border {" +
  "border-right: transparent !important;" +
  "}" +
  ".no-left-border {" +
  "border-left: transparent !important;" +
  "}" +
  "</style>";

export default {
  name: "document_info_payment",
  components: {
  },
  data() {
    this.emitter.on('onDeleteDocument', (response) => {
      console.log(response);
      this.$q.notify({
        progress: true,
        message: "Документ удален",
        icon: 'checkmark',
        color: 'white',
        textColor: 'primary'
      });
      this.connection.getDocumentInfo(
        '1',
        this.$store.state.current_org_id,
        this.$route.params.id,
        'last'
      );
      this.connection.getDocumentInfoShort(
        '1',
        this.$store.state.current_org_id,
        this.$route.params.id
      );
    });

    this.emitter.on('onCreateTxtFromPayement', (response) => {

      fetch(response.url)
        .then((res) => {
          if (!res.ok) {
            throw new Error("Network Problem");
          }
          return res.blob();
        })
        .then((file) => {
          var anchor = document.createElement("a")
          anchor.href = URL.createObjectURL(file);
          anchor.download = "erp_to_kl.txt"
          anchor.setAttribute('target', '_blank')
          anchor.click()
          URL.revokeObjectURL(anchor.href)
          anchor.remove()
        })
        .catch(() => {
          
        });
    });
    this.emitter.on('onRestoreDocument', (response) => {
      console.log(response);
      this.$q.notify({
        progress: true,
        message: "Документ восстановлен",
        icon: 'checkmark',
        color: 'white',
        textColor: 'primary'
      });
      this.connection.getDocumentInfo(
        '1',
        this.$store.state.current_org_id,
        this.$route.params.id,
        'last'
      );
      this.connection.getDocumentInfoShort(
        '1',
        this.$store.state.current_org_id,
        this.$route.params.id
      );
    });
    this.emitter.on('didReceiveDocumentInfo', (response) => {
      // const changes = response.data.changes;
      console.log(response.body);
      this.name = response.data.name;
      this.body = response.data.body;//changes[this.$route.params.change_id].metadata;
      this.changes = response.data.changes;
      this.transfers = response.data.transfers;
      this.is_active = response.data.is_active;
      this.$q.loading.hide();
    });
    this.emitter.on('onCreateUpdTransferWildcard', (data) => {
      console.log(data)
      this.connection.getDocumentInfo(
        '1',
        this.$store.state.current_org_id,
        this.$route.params.id,
        this.$route.params.history_id
      );
    });
    this.emitter.on('didReceiveBillListFlatCompact', (data) => {
      this.bills_list = data.bills.map((element) => {
        return { label: element.full_name, value: element.id }
      });
    });
    if (this.connection.isAuthenticated) {
      this.connection.getDocumentInfo(
        '1',
        this.$store.state.current_org_id,
        this.$route.params.id,
        this.$route.params.history_id
      );
      this.connection.gitBillsListFlatCompact(
        '1',
        this.$store.state.current_org_id,
      );
    } else {
      this.emitter.on('connectionReady', () => {
        this.connection.getDocumentInfo(
          '1',
          this.$store.state.current_org_id,
          this.$route.params.id,
          this.$route.params.history_id
        );
        this.connection.gitBillsListFlatCompact(
          '1',
          this.$store.state.current_org_id,
        );
      })
    }
    return {
      name: ref(''),
      body: ref({}),
      transfers: ref([]),
      changes: ref([]),
      document_id: ref(this.$route.params.id),
      bills_list: ref([]),
      wildcard_summ: ref(null),
      wildcard_name: ref(null),
      wildcard_comment: ref(null),
      wildcard_bill_from: ref(null),
      wildcard_bill_to: ref(null),
      is_active: ref(true),
    }
  },
  methods: {
    formatDate(date) {
      return moment.unix(date).format('DD.MM.YYYY');
    },
    onPrintButtonClick() {
      let mywindow = window.open('', 'PRINT', 'height=650,width=900,top=100,left=150');

      mywindow.document.write(`<html><head><title></title>`);
      mywindow.document.write('</head><body >' + style);
      mywindow.document.write(document.getElementById('print_content').innerHTML);
      mywindow.document.write('</body></html>');

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10*/

      mywindow.print();
      mywindow.close();

      return true;
    },
    createTransferWildcard() {
      this.$q.loading.show();
      this.connection.createUpdTrasferWildcard(
        '1',
        this.$store.state.current_org_id,
        this.$route.params.id,
        this.wildcard_summ,
        this.wildcard_name,
        this.wildcard_comment,
        this.wildcard_bill_from.value,
        this.wildcard_bill_to.value
      )
    },
    onTransferStateChange(value, item_id) {
      this.connection.updateTransferActiveState(
        '1',
        this.$store.state.current_org_id,
        item_id,
        value
      )
    },
    onTransferBillChange(value, item_id, direction) {
      this.connection.updateTransferBillState(
        '1',
        this.$store.state.current_org_id,
        item_id,
        value,
        direction
      )
    },
    onEditDocument() {
      this.$router.push({ name: 'document_edit_payment', params: { id: this.$route.params.id } });
    },
    onDeleteDocument() {
      this.connection.deleteDocument(
        '1',
        this.$store.state.current_org_id,
        this.$route.params.id
      )
    },
    onRestoreDocument() {
      this.connection.restoreDocument(
        '1',
        this.$store.state.current_org_id,
        this.$route.params.id
      )
    },
    onDownload() {
      this.connection.createTxtFromPayement(
        '1',
        this.$store.state.current_org_id,
        [this.$route.params.id]
      )
    }
  }
}
</script>

<style scoped>
@page {
  size: A4;
  margin: 0;
}

.container {
  margin: 0;
  padding: 0;
  font-family: 'Arial', sans-serif;
  font-size: 12px;
  background: white;
  color: black;
  width: 210mm;
  height: 297mm;
  padding: 10mm 10mm 10mm 20mm;
  box-sizing: border-box;
  display: block;
  margin: auto;
  position: relative;
}

table {
  width: 180mm;
  height: 110mm;
  border-collapse: collapse;
  table-layout: fixed;
  margin-top: 10px;
  font-size: 8pt;
}

th,
td {
  border: 0.6mm solid black;
  height: 5mm;
  text-align: center;
  vertical-align: top;
}

tr {
  height: 5mm;
}

.aligned-cells {
  text-align: left;
  vertical-align: bottom;
}

.no-right-border {
  border-right: transparent !important;
}

.no-left-border {
  border-left: transparent !important;
}
</style>